import { useMutation, useQueryClient } from '@tanstack/react-query'
import { mediaKeys } from '../mediaKeys'
import { RequestTypes } from '../requestTypes'
import { useUploadMedia } from './useUploadMedia'
import { eventsKeys } from '../../events'
import { fileNameHelper } from 'src/core/helpers/fileNameHelper'

export const useUploadImage = () => {
  const queryClient = useQueryClient()
  const uploadMedia = useUploadMedia()

  return useMutation<
    { file_key: string; type: string },
    string,
    RequestTypes['uploadImage']
  >({
    mutationFn: async (variables) => {
      const fileData = fileNameHelper(variables.file)
      let imageUploadUrl
      try {
        imageUploadUrl = await uploadMedia.mutateAsync(fileData)
      } catch (e) {
        throw e?.message || e
      }

      return await (mediaKeys.uploadImage as any).mutationFn({
        imageUploadUrl: imageUploadUrl.url,
        ...fileData,
        ...variables,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries(eventsKeys.allEvents._def)
      queryClient.invalidateQueries(eventsKeys.byId._def)
    },
  })
}
