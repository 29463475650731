import {
  createMutationKeys,
  createQueryKeys,
} from '@lukemorales/query-key-factory'
import { RequestTypes } from './requestTypes'
import { APIAxiosInstance } from 'src/core/axios/api-axios-instance'
import { createQueryKeyString } from '../../queryParams'

export const eventsKeys = createQueryKeys('events', {
  allEvents: (payload: RequestTypes['getAllEvents']) => {
    return {
      queryKey: [payload],
      queryFn: async () => {
        const searchParams = createQueryKeyString(payload)
        const { data } = await APIAxiosInstance.get(`/events${searchParams}`)

        return data
      },
    }
  },
  byId: ({ eventId }: RequestTypes['getEvent']) => {
    return {
      queryKey: [eventId],
      queryFn: async () => {
        const { data } = await APIAxiosInstance.get(`/events/${eventId}`)
        return data
      },
    }
  },
})

export const eventsMutationKeys = createMutationKeys('events', {
  createEvent: {
    mutationKey: null,
    mutationFn: async (payload: RequestTypes['createEvent']) => {
      const { data } = await APIAxiosInstance.post(`/events/`, payload)
      return data
    },
  },
  updateEvent: {
    mutationKey: null,
    mutationFn: async (payload: RequestTypes['updateEvent']) => {
      const { data } = await APIAxiosInstance.put(
        `/events/${payload.eventId}`,
        payload,
      )
      return data
    },
  },
  deleteEvent: {
    mutationKey: null,
    mutationFn: async (payload: RequestTypes['deleteEvent']) => {
      const { data } = await APIAxiosInstance.delete(
        `/events/${payload.eventId}`,
      )
      return data
    },
  },
})
