import { useMutation, useQueryClient } from '@tanstack/react-query'
import { eventsKeys, eventsMutationKeys } from '../eventsKeys'
import { RequestTypes } from '../requestTypes'
import { Event } from '../types'
import { useUploadImage } from '../../upload-media'

export const useCreateEvent = () => {
  const queryClient = useQueryClient()
  const uploadImage = useUploadImage()

  return useMutation<Event, string, RequestTypes['createEvent']>({
    mutationFn: async (variables) => {
      const { file, ...rest } = variables
      let file_key = null
      let type = null

      if (file) {
        const data = await uploadImage.mutateAsync({ file })

        file_key = data.file_key
        type = data.type
      }

      const { data } = await (eventsMutationKeys.createEvent as any).mutationFn(
        {
          ...rest,
          file_key,
          type,
        },
      )

      return data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: eventsKeys.allEvents._def,
      })
    },
  })
}
