import { CalendarMonth, LocationOn, Sell } from '@mui/icons-material'
import { Chip, useTheme } from '@mui/material'
import moment from 'moment-timezone'
import { Event } from 'src/core/react-query/features/events/types'

type EventOtherChipsProps = {
  event: Event
}

export const EventOtherChips: React.FC<EventOtherChipsProps> = ({ event }) => {
  const theme = useTheme()
  const startDate = moment
    .utc(event.start)
    .utcOffset(moment().utcOffset())
    .format('Do MMM YYYY, HH:mm')

  const endDate = moment
    .utc(event.end)
    .utcOffset(moment().utcOffset())
    .format('Do MMM YYYY, HH:mm')
  return (
    <>
      <Chip
        color='info'
        variant='outlined'
        avatar={<CalendarMonth />}
        sx={{
          mb: 1,
          mr: 1,
          '.MuiChip-avatar': {
            color: theme.palette.primary.main,
          },
        }}
        label={`${startDate} - ${endDate}`}
      />
      <Chip
        color='info'
        variant='outlined'
        avatar={<LocationOn />}
        sx={{
          mb: 1,
          mr: 1,
          '.MuiChip-avatar': {
            color: theme.palette.primary.main,
          },
        }}
        label={event.location_type}
      />
      <Chip
        color='info'
        variant='outlined'
        avatar={<Sell />}
        sx={{
          mb: 1,
          mr: 1,
          '.MuiChip-avatar': {
            color: theme.palette.primary.main,
          },
        }}
        label={event.entry_type}
      />
    </>
  )
}
