import { useMutation, useQueryClient } from '@tanstack/react-query'
import { eventsKeys, eventsMutationKeys } from '../eventsKeys'
import { RequestTypes } from '../requestTypes'
import { Event } from '../types'
import { useUploadImage } from '../../upload-media'
import { useDeleteMedia } from '../../upload-media/hooks/useDeleteMedia'

export const useUpdateEvent = () => {
  const queryClient = useQueryClient()
  const uploadImage = useUploadImage()
  const deleteMedia = useDeleteMedia()

  return useMutation<Event, string, RequestTypes['updateEvent']>({
    mutationFn: async (variables) => {
      const { file, ...rest } = variables
      let file_key = null
      let type = null

      if (file) {
        const data = await uploadImage.mutateAsync({ file })

        file_key = data.file_key
        type = data.type
      }

      const { data } = await (eventsMutationKeys.updateEvent as any).mutationFn(
        {
          ...rest,
          file_key,
          type,
        },
      )

      return data
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: eventsKeys.byId({ eventId: String(variables.eventId) })
          .queryKey,
      })

      variables?.file && deleteMedia.mutate({ file_key: variables.file_key })
    },
  })
}
