import React from 'react'
import {
  Autocomplete,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  TextField,
} from '@mui/material'
import { useModal } from 'src/components/GlobalModal'
import { Clear } from '@mui/icons-material'
import { useCreateEvent } from 'src/core/react-query/features/events/hooks/useCreateEvent'
import { useUpdateEvent } from 'src/core/react-query/features/events/hooks/useUpdateEvent'
import { Controller, useForm } from 'react-hook-form'
import { Event } from 'src/core/react-query/features/events/types'
import {
  FilterData,
  useGetAllFilters,
} from 'src/core/react-query/features/filters'
import { useSession } from 'next-auth/react'
import { useToast } from 'src/core/toast'
import { LoadingButton } from '@mui/lab'
import { entryTypes } from 'src/core/constants'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import moment from 'moment'
import { Flex } from 'src/components/Flex'

type CreateUpdateEventModalProps = {
  event?: Event
}

export const CreateUpdateEventModal: React.FC<CreateUpdateEventModalProps> = ({
  event,
}) => {
  const { hideModal } = useModal()
  const toast = useToast()
  const createEvent = useCreateEvent()
  const updateEvent = useUpdateEvent()

  const { data: filters = [] } = useGetAllFilters({})
  const { data: session } = useSession()

  const [imageFile, setImageFile] = React.useState<File | null>(null)

  const defaultValues = {
    title: event?.title || '',
    description: event?.description || '',
    categoryId: event?.category_id || '',
    subCategoryIds:
      event?.sub_category_ids
        ?.split(',')
        .map((subCategpry) => Number(subCategpry)) || [],
    locationId: event?.location_id || '',
    entryTypeId:
      entryTypes.find((entryType) => entryType.type === event?.entry_type)
        ?.id || '',

    start: event?.start?.toString() || '',
    end: event?.end?.toString() || '',
    imageUrl: event?.imageUrl || '',
  }

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    control,
    formState: { errors },
  } = useForm({ defaultValues })

  const categoryId = watch('categoryId')
  const subCategoryIds = watch('subCategoryIds')

  const onSubmit = handleSubmit((data) => {
    if (event) {
      updateEvent.mutate(
        {
          ...data,
          eventId: event.id,
          creatorId: session.user.id,
          subCategoryIds: data.subCategoryIds.map((subCategory) =>
            Number(subCategory),
          ),
          file: imageFile,
        },
        {
          onSuccess: () => {
            toast.success('Event updated successfully')
            hideModal()
          },
          onError: () => toast.error('Error updating event'),
        },
      )
    } else {
      createEvent.mutate(
        {
          ...data,
          creatorId: session.user.id,
          subCategoryIds: data.subCategoryIds.map((subCategory) =>
            Number(subCategory),
          ),
          file: imageFile,
        },
        {
          onSuccess: () => {
            toast.success('Event created successfully')
            hideModal()
          },
          onError: () => toast.error('Error creating event'),
        },
      )
    }
  })

  const imageUrl = watch('imageUrl')

  return (
    <form onSubmit={onSubmit}>
      <DialogTitle
        variant='h5'
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        sx={{ pr: 1, fontSize: 20 }}
      >
        Create event
        <IconButton aria-label='close-modal' onClick={hideModal}>
          <Clear />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box display='flex' flexDirection='column'>
          <TextField
            sx={{ mt: 1 }}
            label='Title'
            inputProps={register('title', {
              required: 'Please write a title for your event',
            })}
            error={!!errors.title}
            helperText={errors.title?.message}
          />
          <TextField
            sx={{ mt: 1 }}
            label='Description'
            inputProps={register('description', {
              required: 'Please write a description for your event',
            })}
            error={!!errors.description}
            helperText={errors.description?.message}
          />
          <Flex gap={1}>
            <Controller
              control={control}
              name='start'
              rules={{
                validate: (start) =>
                  start ? true : 'Please select a date range',
              }}
              render={({ field, fieldState: { error } }) => (
                <DateTimePicker
                  sx={{ mt: 1 }}
                  ampm={false}
                  timezone='system'
                  disablePast
                  value={field.value ? moment(field.value) : null}
                  slotProps={{
                    field: {
                      clearable: true,
                      onClear: (e) => {
                        const input =
                          e.currentTarget.parentElement.parentElement.querySelector(
                            'input',
                          )
                        setTimeout(() => {
                          input.blur()
                        })
                      },
                    },
                    textField: {
                      fullWidth: true,
                      helperText: error?.message,
                      error: !!error,
                    },
                  }}
                  label='Start Date'
                  onChange={field.onChange}
                />
              )}
            />
            <Controller
              control={control}
              name='end'
              rules={{
                validate: (end) => (end ? true : 'Please select a date range'),
              }}
              render={({ field, fieldState: { error } }) => (
                <DateTimePicker
                  sx={{ mt: 1 }}
                  ampm={false}
                  timezone='system'
                  disablePast
                  value={field.value ? moment(field.value) : null}
                  slotProps={{
                    field: {
                      clearable: true,
                      onClear: (e) => {
                        const input =
                          e.currentTarget.parentElement.parentElement.querySelector(
                            'input',
                          )
                        setTimeout(() => {
                          input.blur()
                        })
                      },
                    },
                    textField: {
                      fullWidth: true,
                      helperText: error?.message,
                      error: !!error,
                    },
                  }}
                  label='End Date'
                  onChange={field.onChange}
                />
              )}
            />
          </Flex>
          {filters.map((filter) => {
            const isSubcategoryFilter = filter.type === 'subCategory'
            const options = isSubcategoryFilter
              ? filter.data.filter(
                  (subCategory) => subCategory.category_id == categoryId,
                )
              : filter.data
            return (
              <Controller
                control={control}
                name={filter.createEventType}
                rules={{
                  required: `Please select ${filter.name}`,
                }}
                render={({ field, fieldState: { error } }) => {
                  const value =
                    filter.type === 'subCategory'
                      ? options?.filter((item) =>
                          (field.value as number[])?.find(
                            (paramOption) => paramOption == Number(item.id),
                          ),
                        ) || []
                      : options?.find((item) => item.id == field.value) || null

                  return (
                    <Autocomplete
                      disabled={isSubcategoryFilter && categoryId === ''}
                      disablePortal
                      id={filter.type}
                      value={value}
                      options={options}
                      multiple={isSubcategoryFilter}
                      sx={{ mt: 1 }}
                      getOptionLabel={(option: FilterData) => option.type ?? ''}
                      renderOption={(props, option: FilterData) => (
                        <MenuItem
                          {...props}
                          key={option.id}
                          value={option.type}
                        >
                          {option.type}
                        </MenuItem>
                      )}
                      renderInput={(params) => {
                        const textfieldParams = {
                          ...params,
                          inputProps: {
                            ...params.inputProps,
                            value:
                              params.inputProps.value === 'undefined'
                                ? ''
                                : params.inputProps.value,
                          },
                        }

                        return (
                          <TextField
                            {...textfieldParams}
                            label={filter.name}
                            helperText={error?.message}
                            error={!!error}
                          />
                        )
                      }}
                      onChange={(e, value, reason) => {
                        if (value) {
                          if (Array.isArray(value)) {
                            field.onChange(value.map((item) => item.id))
                          } else {
                            field.onChange(value.id)
                          }
                        } else field.onChange('')

                        if (filter.type === 'category') {
                          setValue('subCategoryIds', [])
                        }
                      }}
                    />
                  )
                }}
              />
            )
          })}

          <Button
            sx={{ mt: 1, width: '50%' }}
            variant='contained'
            component='label'
          >
            Upload Image
            <Controller
              control={control}
              name='imageUrl'
              render={({ field, fieldState: { error } }) => (
                <input
                  hidden
                  accept='image/*'
                  type='file'
                  onChange={(event) => {
                    field.onChange(event.target.files[0])
                    setImageFile(event.target.files[0])
                  }}
                />
              )}
            />
          </Button>
          {(imageUrl || imageFile) && (
            <img
              src={imageFile ? URL.createObjectURL(imageFile) : imageUrl}
              alt='event-image'
              width='400'
              height='100%'
              style={{
                marginTop: '8px',
                objectFit: 'cover',
              }}
            />
          )}
        </Box>
      </DialogContent>
      <DialogActions sx={{ pb: 2, pr: 3 }}>
        <Button
          disabled={createEvent.isLoading || updateEvent.isLoading}
          variant='outlined'
          onClick={hideModal}
        >
          Cancel
        </Button>
        <LoadingButton
          loading={createEvent.isLoading || updateEvent.isLoading}
          variant='contained'
          type='submit'
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </form>
  )
}
