import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { eventsKeys } from '../eventsKeys'
import { RequestTypes } from '../requestTypes'
import { Event } from '../types'

export const useGetAllEvents = (
  payload: RequestTypes['getAllEvents'],
  options?: UseQueryOptions<Event[], string, Event[]>,
) => {
  return useQuery<Event[], string, Event[]>({
    ...eventsKeys.allEvents(payload),
    ...options,
  })
}
