export const entryTypes = [
  {
    id: '1',
    type: 'Free',
  },
  {
    id: '2',
    type: 'Paid',
  },
]
