import moment from 'moment'

export const fileNameHelper = (
  file: File,
): { file_key: string; type: string } => {
  const date = new Date()
  const formattedDate = moment(date, 'yyyy-M-dd')

  // If file selected: load file to AWS
  // Send file to AWS
  const fileKey = `event-man/date=${formattedDate}/${file?.name}` || ''
  const fileType = file?.type || ''

  return { file_key: fileKey, type: fileType }
}
